<template>
  <div>{{content}}</div>
</template>
<script>
export default {
  name:'Item34Acttiv',
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  mounted () {
  },
}
</script>

